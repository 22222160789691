@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Encode Sans", sans-serif;
}
@layer components{
  .page-title{
      @apply md:text-[1.8em] font-[700] text-primary text-[1.5em]
  }
  .input{@apply w-full bg-[#f4f6f8] rounded-[5px] h-[39px] px-5 relative flex items-center}
  .label{@apply text-[#252631] font-[400] text-[14px]}
  .pryBtn{@apply text-white font-[600] text-[13px] input w-fit bg-primary hover:bg-tertiary px-14 cursor-pointer}
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding-right: 0;
}

select::-ms-expand {
  display: none;
}

@keyframes moveLine {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(100%);
  }
}

.loading-line {
  width: 100%;
  animation: moveLine 5s linear infinite;
}